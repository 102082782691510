import { ReactNode, useEffect } from 'react';
import useSmartEdit from '@hooks/useSmartEdit';
import { PageWithSlots } from '@api/interfaces/pageApi';
import { getCmsTemplate } from '@helpers/cmsTemplateHelper';

export interface Props {
  children?: ReactNode;
  cmsPage?: PageWithSlots;
}

const Page = ({ children, cmsPage }: Props) => {
  const { isSmartEditEnabled, toggleBodyClasses } = useSmartEdit();

  useEffect(() => {
    if (cmsPage) {
      toggleBodyClasses(isSmartEditEnabled, cmsPage?.uid, cmsPage.uuid, cmsPage?.catalogVersionUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmartEditEnabled, cmsPage]);

  return getCmsTemplate(cmsPage, children);
};

export default Page;
